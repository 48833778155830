import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid } from "@mui/material";

import { useNavigate, useSearchParams } from "react-router-dom";

import { SearchCounts } from "./SearchCounts";
import { filterTypes } from "../../Utils/helper";
import Icon from "../../Components/Icons/icons";
import qs from "qs";
import FilterBox from "./Components/FilterBox";

import { getData } from "../../Utils/api";
import { Results } from "./Components/Results";

import {
  CustomerInfoHeading,
  KBChips,
  KBTagsStack,
  KbSearchPageContainer,
  MainContent,
  NoDataFoundWrapper,
  NoResultsText,
  PageWrapper,
  QueryWord,
} from "./styles";

import { CircularLoader } from "../../Components/CircularLoader";

interface ICustomerEngagementType {
  createdAt: string;
  id: number;
  name: string;
  publishedAt: string;
  updatedAt: string;
}

interface ICustomer {
  name?: string;
  synopsis?: string;
  customer_outcome?: string;
  current_status?: string;
  customer_engagement_types?: any;
  engagement_start_date?: Date;
  strict_nda?: boolean;
  nda_description?: string;
  marquee?: boolean;
  [key: string]: any;
}

const SearchListing = () => {
  const [showLoader, setLoader] = useState<boolean>(true);
  const [searchResult, setSearchResult] = useState<ICustomer[]>([]);
  const [displayRecords, setDisplayRecords] = useState<ICustomer[]>([]);
  const [active, setActive] = useState<string>("total");

  const [marqueeCustomers, setMarqueeCustomers] = useState<ICustomer>([]);
  const searchQuery = useRef("");

  const [selectedFilter, setSelectedFilter] = useState([
    { name: "", type: "" },
  ]);
  const [userSelectedFilters, setUserSelectedFilters] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams({});

  useEffect(() => {
    const query = searchParams.get("query") || "";
    const type = searchParams.get("type") || "";
    let url = "";
    let createFilters: any = [];
    if (type.length) {
      searchQuery.current = query;
      createFilters.push({ name: query.toLowerCase(), type });

      const queryParam = qs.stringify(
        {
          filters: {
            $or: [
              {
                business_tags: {
                  name: {
                    $startsWith: query,
                  },
                },
              },
              {
                tech_tags: {
                  name: {
                    $startsWith: query,
                  },
                },
              },
              {
                name: {
                  $contains: query,
                },
              },
              {
                customer_compliances: {
                  name: {
                    $startsWith: query,
                  },
                },
              },
            ],
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );

      url =
        type === "name"
          ? `/customers?filters[${type}][$contains]=${query}&&populate=*`
          : type === "all"
          ? `/customers?${queryParam}&&populate=deep,3`
          : `/customers?filters[${type}][name][$startsWith]=${query}&&populate=*`;
      setLoader(true);
      getCustomerData(url);
    } else {
      searchQuery.current = "SEARCH";
      let updatedFilterQuery: any = {
        filters: {
          $and: [],
        },
      };

      Object.entries(filterTypes).map(([type, filter]: any) => {
        const getFilterFromSearchParam = searchParams.get(filter)?.split('|');
        if (getFilterFromSearchParam) {
          getFilterFromSearchParam.forEach((tag) => {
            if (type === "customer_location") {
              updatedFilterQuery.filters.$and.push({
                [type]: { location: { $startsWith: tag } },
              });
            } else if (type === "current_stage") {
              updatedFilterQuery.filters.$and.push({
                [type]: { $startsWith: tag },
              });
            } else {
              updatedFilterQuery.filters.$and.push({
                [type]: { name: { $startsWith: tag } },
              });
            }

            createFilters.push({ name: tag, type });
          });
        }
      });

      const updatedFilterQueryParam = qs.stringify(updatedFilterQuery, {
        encodeValuesOnly: true, // prettify URL
      });

      url = `/customers?${updatedFilterQueryParam}&&populate=*`;
      setLoader(true);
      getCustomerData(url);
    }

    async function getCustomerData(url: string) {
      const data = await getData(url);
      if (data && data.length) {
        setLoader(false);
        setSelectedFilter(createFilters);
        const customers: ICustomer[] = data;
        const sortByYear: ICustomer[] = customers.sort(
          (a: ICustomer, b: ICustomer) =>
            new Date(a?.attributes.engagement_start_date!).valueOf() -
            new Date(b?.attributes.engagement_start_date!).valueOf()
        );
        window.dataLayer.push({
          event: "query",
          query,
        });
        setSearchResult(sortByYear);
        setDisplayRecords(sortByYear);
        setMarqueeCustomers(
          customers.filter(
            (customer: ICustomer) => customer.attributes.marquee === true
          )
        );
      } else {
        setLoader(false);
        setSearchResult([]);
        setDisplayRecords([]);
        setMarqueeCustomers([]);
        window.dataLayer.push({
          event: "query_no_results",
          query,
        });
      }
    }
  }, [searchParams]);

  const handleActiveTab = (selectedTab: string) => {
    const customers = [...searchResult];
    switch (selectedTab) {
      case "total":
        setDisplayRecords(searchResult);
        setActive("total");
        break;

      case "active":
        setDisplayRecords(
          customers.filter(
            (customer: ICustomer) =>
              customer.attributes.current_status === "Active"
          )
        );
        setActive("active");
        break;

      case "marquee":
        setDisplayRecords(
          customers.filter(
            (customer: ICustomer) => customer?.attributes?.marquee === true
          )
        );
        setActive("marquee");
        break;

      default:
        setDisplayRecords(searchResult);
    }
  };

  const handleCheckedFilters = (checkedFilters: any) => {
    setUserSelectedFilters(checkedFilters);
  };

  const handleDeleteFilter = (filter: { name: string; type: string }) => {
    const selectedTagFilterType = filterTypes[filter?.type];

    const selectedFilterQuery = searchParams.get(selectedTagFilterType)
      ? searchParams.get(selectedTagFilterType)
      : searchParams.get("query")?.toLowerCase();

    const selectedFilterQueryArr = selectedFilterQuery?.split("|");

    const newArr: any = selectedFilterQueryArr?.filter(
      (f) => f !== filter.name
    );

    const updatedFilterQuery =
      newArr.length > 1 ? newArr?.join("|") : newArr[0];

    const finalFilterTypeQuery = updatedFilterQuery
      ? `${selectedTagFilterType}=${updatedFilterQuery}`
      : "";

    const typeArrWithoutSelectedType = Object.entries(filterTypes).filter(
      ([filterType, param]) => filterType !== filter.type
    );

    const finalQueryArr = typeArrWithoutSelectedType.map(
      ([filterType, param]: any) => {
        const typeQuery = searchParams.get(param);
        if (typeQuery !== null && typeQuery !== undefined) {
          return `${param}=${typeQuery}`;
        }
      }
    );

    finalQueryArr.push(finalFilterTypeQuery);

    setSearchParams(finalQueryArr.join("&"));
  };

  return (
    <KbSearchPageContainer>
      <>
        {showLoader ? (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularLoader size={60} />
          </Box>
        ) : searchResult && searchResult?.length > 0 ? (
          <PageWrapper>
            <FilterBox
              selectedFilter={selectedFilter}
              data={displayRecords}
              checkedFilters={handleCheckedFilters}
            />
            <MainContent>
              <Container>
                <SearchCounts
                  userSelectedFilters={userSelectedFilters}
                  active={active}
                  onClick={handleActiveTab}
                  marqueeCustomersCount={marqueeCustomers.length}
                  query={searchQuery.current}
                  totalProjectsCount={searchResult.length}
                  activeProjectsCount={
                    searchResult.filter(
                      (customer: ICustomer) =>
                        customer?.attributes?.current_status === 'Active'
                    ).length
                  }
                />
                <Box pb={2}>
                  <KBTagsStack direction="row">
                    {userSelectedFilters.map((tag: any) => (
                      <KBChips
                        onDelete={() => handleDeleteFilter(tag)}
                        key={tag?.name}
                        variant={"outlined"}
                        label={tag?.name}
                      />
                    ))}
                  </KBTagsStack>
                </Box>
              </Container>

              <Box flexGrow={1} bgcolor={"#f8f8f8"} overflow={"auto"}>
                <Container>
                  <Grid container spacing={2} pt={"1.25rem"}>
                    <Grid item xs={4}>
                      <Box px={2}>
                        <CustomerInfoHeading title="Company" />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box px={2}>
                        <CustomerInfoHeading title="Engagement Type" />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box px={2}>
                        <CustomerInfoHeading title="Key outcomes" />
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box px={2} textAlign={"right"}>
                        <CustomerInfoHeading title="Engagement term" />
                      </Box>
                    </Grid>
                  </Grid>

                  {displayRecords ? (
                    displayRecords?.map((customer: ICustomer) => {
                      return (
                        <Results
                          key={Math.random()}
                          customer={customer?.attributes}
                        />
                      );
                    })
                  ) : (
                    <div>loading</div>
                  )}
                </Container>
              </Box>
            </MainContent>
          </PageWrapper>
        ) : (
          <NoDataFoundWrapper>
            <Icon width={133} height={90} kind="no-data-found" />
            <NoResultsText title="No results found for" />
            <QueryWord title={`\`${searchParams?.get("query") || ""}\``} />
          </NoDataFoundWrapper>
        )}
      </>
    </KbSearchPageContainer>
  );
};

export default SearchListing;
