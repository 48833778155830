import { Type } from "./constants";

export function getFilterTags(data: any[], type: string) {
  const newTech_Tags = data?.map((item: any) => {
    switch (type) {
      case Type.TECH:
        return item?.attributes?.tech_tags?.data;
      case Type.BUSINESS:
        return item?.attributes?.business_tags?.data;
      case Type.ENGAGEMENT:
        return item?.attributes?.customer_engagement_types?.data;
      case Type.FUNDING:
        return item?.attributes?.level_funding_now;
      case Type.LOCATION:
        return item?.attributes?.customer_location?.data;
      case Type.COMPLIANCES:
        return item?.attributes?.customer_compliances?.data;
      case Type.STAGE:
        return item?.attributes?.current_stage;
    }
  });
  if (type === "customer_location") {
    let locationArray = [];
    locationArray = newTech_Tags.map(
      (subArray: any) => subArray?.attributes?.location
    );
    return Array.from(new Set(locationArray));
  }
  if (type === "level_funding_now") {
    let namesArray = [];
    namesArray = newTech_Tags.map(
      (subArray: any) => subArray?.data?.attributes?.name
    );
    return Array.from(new Set(namesArray));
  }
  if (
    type === "business_tags" ||
    type === "customer_compliances" ||
    type === "tech_tags" ||
    type === "customer_engagement_types"
  ) {
    const namesArray = newTech_Tags.map((subArray: any) => {
      return subArray.map((item: any) => item?.attributes?.name);
    });
    const singleArray: string[] = namesArray.flat();
    const deduplicatedArray: string[] = Array.from(new Set(singleArray));
    return deduplicatedArray;
  }
  if (type === "current_stage") {
    return ["Early", "Growth", "Mature"];
  }
}

export function areObjectsEqual(obj1: any, obj2: any) {
  // Check if both arguments are objects
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each key and value in both objects
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    // Recursively check nested objects or arrays
    if (typeof value1 === "object" && typeof value2 === "object") {
      if (!areObjectsEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}
